<template>
  <label
    class="ui-input"
    :class="[{error}, {disabled}]"
    @mouseenter="showIconClear = true"
    @mouseleave="mouseLeaveHandle"
  >
    <div
      v-if="$slots['left-icon']"
      class="ui-input__icon ui-input__icon--left"
    >
      <slot name="left-icon" />
    </div>

    <input
      :value="modelValue"
      :type="
        type === 'password' ? (isHidden ? 'password' : 'text') : type
      "
      :name="name"
      :placeholder="$t(placeholder || '')"
      class="ui-input__input"
      :inputmode="type === 'number' ? 'numeric' : null"
      :class="[{directionRight}, {'ui-input_no-spin' : spin}]"
      @blur="leaveInput(modelValue, required, $event)"
      @input="updateValue"
      @change="emit('change', $event)"
      @keypress="onKeypress"
      @paste="onPaste"
      @focus="focusHadle"
      :min="minValue"
    />
    <div
      v-if="clearable && modelValue && showIconClear"
      class="ui-input__clear"
      @click="clear"
      @mousedown.prevent
      @mouseenter="
        () => {
          if (!error) {
            color = '#3D424A';
          }
        }
      "
      @mouseleave="
        () => {
          if (!error) {
            color = '#839AAF';
          }
        }
      "
    >
      <IconCloseSvg :color="color" />
    </div>
    <div
      v-if="type === 'password'"
      class="ui-input__hide"
      @click="changeHide"
    >
      <IconEyeOpen v-if="!isHidden" />
      <IconEyeClosed v-else />
    </div>
    <div
      v-if="$slots['right-icon']"
      class="ui-input__icon ui-input__icon--right"
    >
      <slot name="right-icon" />
    </div>
  </label>
</template>

<script lang="ts" setup>
import {ref} from '#imports';
import IconClear from '~/assets/svg/close.svg';
import IconClearGray from '~/assets/svg/close_gray.svg';
import IconEyeOpen from '~/assets/svg/eye.open.svg';
import IconCloseSvg from '~/components/svg/IconCloseSvg.vue';
import IconEyeClosed from '~/assets/svg/eye.closed.svg';
import {useI18n} from 'vue-i18n';

const emit = defineEmits([
  'update:modelValue',
  'input',
  'change',
  'blur',
  'paste',
  'focus',
  'clear',
]);

type Props = {
  placeholder?: string;
  name?: string;
  required?: boolean;
  error?: string | boolean;
  modelValue: string | number;
  label?: string;
  cyrillicOnly?: boolean;
  type?: string;
  minValue?: number;
  disabled?: boolean;
  clearable?: boolean;
  directionRight?: 'right';
  keyPressCodeDisabled?: number[];
  spin?: boolean;
};

const props = defineProps<Props>();

const isFocused = ref(false);

const color = ref('#839AAF');
const {t} = useI18n();

watch(
  () => props.error,
  (val) => {
    if (val) {
      color.value = '#d24827';
    } else {
      color.value = '#839AAF';
    }
  },
);

const mouseLeaveHandle = () => {
  if (!isFocused.value) {
    showIconClear.value = false;
  }
};

const updateValue = (event: Event) => {
  emit('input', event);
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
};

function clear() {
  emit('update:modelValue', '');
  emit('clear', '');
}

function onPaste(event: Clipboard) {
  emit('paste', event);
  if (props.cyrillicOnly) {
    const regex = /^[а-яА-ЯёЁ\s-]+$/;
    navigator.clipboard.readText().then((res: string) => {
      const copyText = res;
      if (!regex.test(copyText)) {
        clear();
        return false;
      }
    });
  }
}

function onlyCyrillic(evt: KeyboardEvent) {
  const regex = /^[а-яА-ЯёЁ\s-]+$/;
  const key = evt.key;
  if (!regex.test(key)) {
    evt.preventDefault();
    return false;
  }
}

function onKeypress(evt: KeyboardEvent) {
  if (props.cyrillicOnly) {
    onlyCyrillic(evt);
  }

  if (
    props.keyPressCodeDisabled &&
    props.keyPressCodeDisabled.includes(evt.keyCode)
  ) {
    evt.preventDefault();
  }
}

const isRequired = ref(false);

const isHidden = ref(true);

const showIconClear = ref(false);

const changeHide = () => {
  isHidden.value = !isHidden.value;
};

const checkRequire = (
  value: string,
  required: boolean | undefined,
) => {
  turnRequired(!value.length && required);
};

const turnRequired = (value: boolean | undefined) =>
  (isRequired.value = value!);

const leaveInput = (
  value: string,
  required: boolean | undefined,
  event: Event,
) => {
  emit('blur', event);
  checkRequire(value, required);
  isFocused.value = false;
  showIconClear.value = false;
};

const focusHadle = (event: Event) => {
  emit('focus', event);
  isFocused.value = true;
  showIconClear.value = true;
};
</script>
<style lang="scss" scoped>
.ui-input {
  align-items: center;
  background: #f4f6fd;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  overflow: hidden;
  height: 40px;
  border: 1px solid transparent;
  padding: 0 12px;

  &_no-spin {
    -moz-appearance: textfield; /* Firefox */

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }


  &:hover {
    border: 1px solid #e1e5f0;
    input::placeholder {
      color: #3d424a;
    }
  }
  &:focus-within {
    border: 1px solid #839aaf;
  }

  .directionRight {
    direction: rtl;
  }

  &__clear {
    cursor: pointer;
  }

  &__hide {
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      display: block;
    }
  }
  &__icon--left {
    display: flex;
  }
  input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    color: #3d424a;
    &::placeholder {
      color: #839aaf;
    }
    &:focus::placeholder {
      color: #839aaf;
    }
  }

  &.error {
    border: 1px solid #d24827;
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}
</style>
